<template>
 
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#437ccc" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-card-title class="elevation-0">
          <span> <h3>User List</h3></span>
          <v-spacer></v-spacer>
          <v-text-field clearable label="Search by Name" prepend-icon="mdi-magnify" v-model="Search" @input="getData()">

          </v-text-field>
        </v-card-title>
   
        <!-- <v-layout wrap justify-start v-if="userList.length > 0">
          <v-flex
            xs12
            align-self-center
            v-for="(item, i) in userList"
            :key="i"
            fill-height
            px-2
            py-1
          >
            <v-card tile flat outlined>
              <v-layout wrap justify-center py-2>
                <v-flex
                  xs12
                  lg2
                  align-self-start
                  text-left
                  pa-2
                  align-self-center
                >
                
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  align-self-start
                  text-left
                  pa-2
                  align-self-center
                >
                  <span style="font-family: pregular">
                    {{ item.first_name }} &nbsp; {{ item.last_name }}</span
                  >
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  align-self-start
                  text-left
                  pa-2
                  align-self-center
                >
                  <span style="font-family: pregular"> {{ item.phone }}</span>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  align-self-start
                  text-left
                  pa-2
                  align-self-center
                >
                </v-flex>

              
                <v-spacer></v-spacer>
                <v-flex xs6 sm6 lg1 pa-2 align-self-center text-center>
                  <router-link
                    :to="'/userview?id=' + item._id"
                    style="text-decoration: none"
                  >
                    <v-icon color="primary"> mdi-eye </v-icon>
                  </router-link>
                </v-flex>
                <v-flex xs6 sm6 lg1 pa-2 align-self-center text-center>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-on="on" v-bind="attrs">
                        mdi-delete
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this user
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item._id)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card tile>
              <v-card-title>
                <span class="headline">Edit Category</span>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 pt-5 text-left>
                    <v-text-field
                      v-model="editingitem.name"
                      label="Category Name"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 align-self-center text-left pt-5>
                    <v-select
                      v-model="editingitem.jobCategory"
                      :items="categoryList"
                      item-text="name"
                      item-value="_id"
                      item-color="#8d8d8d"
                      color="#8d8d8d"
                      outlined
                      dense
                      label="Job Category"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pt-5>
                    <span>Content</span>
                    <br />
                    <vue-editor
                      class="textField2 pt-1"
                      v-model="editingitem.content"
                    ></vue-editor>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pt-5>
                    <v-text-field
                      v-model="editingitem.location"
                      label="Location"
                      :rules="[rules.required]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="getdData">
                  Close
                </v-btn>
                <v-btn
                  v-if="!g"
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout> -->
           <v-card class="sbold">
           <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left sbold" style="font-size: 15px">Name</th>
                  <th class="text-left sbold" style="font-size: 15px">Phone Number</th>
                  <th class="text-right sbold" style="font-size: 15px">View</th>
                  <th class="text-right sbold" style="font-size: 15px"  >Delete </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in userList" :key="i">
                  <td>
                       {{ item.first_name }} &nbsp; {{ item.last_name }}
                  </td>
                  
                  <td >
                    {{ item.phone }} 
                  </td>
                   <td class="text-right"  >

                     <router-link
                    :to="'/userview?id=' + item._id"
                    style="text-decoration: none"
                  >
                    <v-icon small color="primary"> mdi-eye </v-icon>
                  </router-link>
                   
                  </td>
                   <td  class="text-right">
                   <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="error" v-on="on" v-bind="attrs">
                        mdi-delete
                      </v-icon>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this user
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item._id)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
           </v-card>




        <!-- <v-layout wrap justify-start v-else>
          <v-flex py-16 xs12 text-center>
            <span class="bold"> No Data found! </span>
          </v-flex>
        </v-layout> -->
      </v-flex>
    </v-layout>
    <v-layout wrap pt-3>
      <v-flex xs12 text-center>
        <v-pagination v-model="page" :length="pagelength"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
 
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      Search:null,
      userList: [],
      employeeList: [],
      page: 1,
      limit: 20,
      pagelength: 5,

      //abov employee lines
      json_fields: {
        Date: "Edate",
        name: "name",
        JobCategory: "cat",
        JobTitle: "title",
      },

      exel: {
        name: "",
        cat: "",
        Edate: "",
        title: "",
      },

      so: [],

      apSearch: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      categoryList: [],
      titleList: [],
      pageCount: 0,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    if (localStorage.PRE == "/employeeview") {
      if (localStorage.fromDateE != "null") {
        this.fromDate = localStorage.fromDateE;
      } else {
        this.fromDate = "";
      }
      if (localStorage.toDateE != "null") {
        this.toDate = localStorage.toDateE;
      } else {
        this.toDate = "";
      }

      if (localStorage.Esearch != "null") {
        this.apSearch = localStorage.Esearch;
      } else {
        this.apSearch = "";
      }
    }

    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  watch: {
    page() {
      this.getData();
    },

    // fromDate() {
    //   this.getData();
    // },
    // toDate() {
    //   this.getData()
    // },
    // apSearch()
    // {
    //   this.keySearch();
    // }
  },

  methods: {
    getData() {
      // localStorage.setItem("fromDateE", this.fromDate);
      // localStorage.setItem("toDateE", this.toDate);
      // localStorage.setItem("Esearch", this.apSearch);

      this.appLoading = true;
      axios({
        url: "/admin/getall/users",
        method: "post",
        data: {
          page: this.page,
          limit: 20,
          keyword:this.Search,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.userList = response.data.data;
            this.pagelength = Math.ceil(response.data.totalLength / this.limit);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    DateFormatted(params) {
      params = moment(params).subtract(5, "h").subtract(30, "m");
      return params ? moment(params).format("DD-MMM-Y") : "";
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat;
      axios({
        url: "/admin/remove/user",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // search
    keySearch() {
      this.appLoading = true;
      axios({
        url: "/employee/search",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.apSearch,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
          this.pagelength = Math.ceil(response.data.length / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //end
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["jobCategory"] = this.jobCategory;
      data["location"] = this.location;
      data["content"] = this.content;
      axios({
        url: "/job/title/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.jobCategory = null;
            this.location = null;
            this.content = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["jobCategory"] = this.editingitem.jobCategory;
      data["location"] = this.editingitem.location;
      data["content"] = this.editingitem.content;
      axios({
        url: "/job/title/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.cl >>> fieldset {
  border-color: rgba(250, 162, 0, 0.986);
  border-radius: 50px;
}
</style>